import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { CancellationDeflection } from '@lawnstarter/ls-react-common/enums';

import {
  CancellationDeflectionChangePro,
  CancellationDeflectionMoving,
  CancellationDeflectionPause,
  CancellationDeflectionSupport,
} from '@src/components/templates';
import { useRouteNavigation } from '@src/hooks';

import { DeflectionElementProp } from './types';

const elements = {
  [CancellationDeflection.ChangePro]: CancellationDeflectionChangePro,
  [CancellationDeflection.ContactSupport]: CancellationDeflectionSupport,
  [CancellationDeflection.Moving]: CancellationDeflectionMoving,
  [CancellationDeflection.Pause]: CancellationDeflectionPause,
};

export function DeflectionElement({ deflection, onNextStep, params }: DeflectionElementProp) {
  const { navigate } = useRouteNavigation();

  const props = {
    [CancellationDeflection.ChangePro]: {
      onPrimaryPress: () => navigate(WebRoutes.changeMyPro, { params }),
      onSecondaryPress: onNextStep,
    },
    [CancellationDeflection.ContactSupport]: {
      onPrimaryPress: () => navigate(WebRoutes.supportFromCancel, { params }),
      onSecondaryPress: onNextStep,
    },
    [CancellationDeflection.Moving]: {
      onPrimaryPress: onNextStep,
    },
    [CancellationDeflection.Pause]: {
      onPrimaryPress: () => navigate(WebRoutes.pauseService, { params }),
      onSecondaryPress: onNextStep,
    },
  };

  const Element = elements[deflection];
  return <Element {...props[deflection]} />;
}
